<template>
	<div class="order">
		<v-row>
			<v-col cols="12" lg="5">
				<h1>Предложение №{{ order.ID }}</h1>
			</v-col>
			<v-col cols="12" lg="7" class="pt-0">
				<v-row class="d-flex align-center">
					<v-col cols="12" sm="8" class="order-sm-last">
						<div class="ml-8 d-flex justify-end">
							<v-btn class="operation-btn" icon
								   title="Удалить предложение"
								   @click="onDeleteOrder">
								<v-icon>mdi-delete-outline</v-icon>
							</v-btn>

							<v-btn :disabled="!isExportEnabled" :loading="loadingExport" class="operation-btn"
								   :title="isExportEnabled?'Экспорт в Google Таблицы':'Подключите аккаунт в Настройках'"
								   @click="onExport">
								<v-icon>mdi-google-spreadsheet</v-icon>
								<span class="operation-btn__label">Экспорт</span>
							</v-btn>

							<v-btn :color="isSaved?'success':'primary'" :loading="loadingSave" class="operation-btn"
								   title="Сохранить предложение"
								   @click="onSave">
								<transition name="fade" mode="out-in">
							<span v-if="isSaved" key="s1">
								<v-icon>mdi-check</v-icon>
							</span>
									<span v-else key="s2" class="d-inline-flex align-center">
								<v-icon>mdi-content-save-outline</v-icon>
								<span class="operation-btn__label">Сохранить</span>
							</span>
								</transition>
							</v-btn>
						</div>
					</v-col>
					<v-col cols="12" sm="4">
						<v-select v-model="order.DISCOUNT" :items="discounts" label="Применить скидку"/>
					</v-col>
				</v-row>
			</v-col>
		</v-row>

		<v-data-table class="mb-2"
					  :headers="headers"
					  :items="currentProducts"
					  :items-per-page="-1"
					  show-expand
					  item-key="ID"
					  disable-sort
					  hide-default-footer
					  @click:row="onEditProduct">

			<template v-slot:item.number="{ _, index }" class="d-flex justify-end">
				{{ index + 1 }}
			</template>

			<template v-slot:item.MODULE_IDS="{ item }" class="d-flex justify-end">
				<div v-if="item.MODULE_IDS">
					{{ moduleArticle(item.MODULE_IDS) }}
				</div>
				<div v-else>Модулей нет</div>
			</template>

			<template v-slot:item.HEIGHT="{ item }" class="d-flex justify-end">
				<div v-if="item.MODULE_IDS">
					{{ productHeight(item.MODULE_IDS) | nf(1) }} м
				</div>
				<div v-else>0 м</div>
			</template>

			<template v-slot:item.AMOUNT="{ item }">
				<div class="d-flex justify-center">
					<quantity v-model="item.AMOUNT" @input="onEmitInput(item)"/>
				</div>
			</template>

			<template v-slot:item.ID="{ item }" class="d-flex justify-end">
				{{ item.ID }}
			</template>

			<template v-slot:item.PRICE="{ item }">
				<div v-if="order.DISCOUNT" class="caption text-no-wrap grey--text text-decoration-line-through">
					{{ productPrice(item, true) | nf }} ₽
				</div>
				<div class="text-no-wrap">
					{{ productPrice(item) | nf }} ₽
				</div>
			</template>

			<template v-slot:item.productTotalPrice="{ item }">
				<div v-if="order.DISCOUNT" class="caption text-no-wrap grey--text text-decoration-line-through">
					{{ productTotalPrice(item, true) | nf }} ₽
				</div>
				<div class="text-no-wrap">
					{{ productTotalPrice(item) | nf }} ₽
				</div>
			</template>

			<template v-slot:item.actions="{ item }">
				<div class="d-flex justify-end flex-nowrap">
					<v-btn icon @click.stop="onEditProduct(item)" title="Редактировать">
						<v-icon>mdi-pencil</v-icon>
					</v-btn>
					<v-btn icon @click.stop="onDeleteProduct(item)" title="Удалить">
						<v-icon>mdi-delete-outline</v-icon>
					</v-btn>
				</div>
			</template>

			<template v-slot:body.append>
				<tr>
					<td colspan="5"/>
					<td colspan="2">
						<div class="d-flex justify-end align-center">
							<div class="mr-4"><span>Итого:</span></div>
							<div>
								<div v-if="order.DISCOUNT"
									 class="caption text-no-wrap grey--text text-decoration-line-through">
									{{ totalOrderPriceWithoutDiscount | nf }} ₽
								</div>
								<b>{{ totalOrderPrice | nf }} ₽</b>
							</div>
						</div>
					</td>
					<td colspan="1"/>
				</tr>
			</template>

			<template v-slot:expanded-item="{ headers, item }">
				<td v-if="item.MODULE_IDS" class="order__expand-row" :colspan="headers.length">
					<product-view :item="item"
								  readonly
								  :modules="modules"
								  :current-modules="modulesOf(item)"/>
<!--					<product-modules :moduleIds="item.MODULE_IDS"
									 :readonly="true"
									 :orderId="id"
									 :productId="item.ID"/>-->
				</td>
			</template>

		</v-data-table>

		<v-btn color="primary"
			   @click="$router.push({name: 'product-new', params: {orderId: order.ID}})">
			<v-icon class="mr-2">mdi-plus</v-icon>
			Добавить позицию
		</v-btn>

		<v-dialog v-model="withExportDialog" width="400">
			<v-card v-if="loadingExport">
				<v-card-title class="justify-center">Экспортируем в Google Таблицы</v-card-title>
				<v-card-text class="d-flex flex-column align-center">
					<p>Пожалуйста, подождите...</p>
					<v-progress-circular indeterminate/>
				</v-card-text>
			</v-card>
			<v-card v-else-if="exportError">
				<v-card-title class="justify-center">Ошибка!</v-card-title>
				<v-card-text>
					<p>{{ exportError }}</p>
				</v-card-text>
				<v-card-actions class="justify-center">
					<v-btn @click="withExportDialog = false" color="primary">Ок</v-btn>
				</v-card-actions>
			</v-card>
			<v-card v-else>
				<v-card-title class="justify-center">Готово!</v-card-title>
				<v-card-text>
					<p>Документ создан на Вашем Google Диске:</p>
					<a :href="exportUrl" target="_blank">{{ exportUrl }}</a>
				</v-card-text>
				<v-card-actions class="justify-center">
					<a :href="exportUrl" target="_blank">
						<v-btn @click="withExportDialog = false" color="primary">Открыть</v-btn>
					</a>

				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import ProductTable from "@/components/product/product-table";
import ProductView from "@/components/product/product-view";
import {debounce} from "lodash";
import Config from "@/config";
import Quantity from "@/components/ui/quantity";
//import ProductModules from "@/components/ui/product-modules";
import {totalPrice} from "@/utils/product";

export default {
	name: "order",
	components: {Quantity, ProductTable, ProductView},
	props: ['id'],
	data() {
		return {
			headers: [
				{text: '#', value: 'number'},
				{text: 'Состав модулей', value: 'MODULE_IDS', align: 'left', width: "35%"},
				{text: 'Высота', value: 'HEIGHT', align: 'right'},
				{text: 'Количество', value: 'AMOUNT', align: 'center'},
				{text: 'Цена', value: 'PRICE', align: 'right'},
				{text: 'Итого', value: 'productTotalPrice', align: 'right'},
				{text: '', value: 'actions', align: 'right', sortable: false},
			],
			currentProducts: [],

			withDeleteDialog: false,
			deleteId: null,
			isSaved: false,

			loadingSave: false,
			withExportDialog: false,
			loadingExport: false,
			exportUrl: null,
			exportError: null,

			// todo move to CMS
			discounts: [
				{value: .0, text: "- без скидки -"},
				{value: .05, text: "5%"},
				{value: .10, text: "10%"},
				{value: .15, text: "15%"},
				{value: .20, text: "20%"},
				{value: .25, text: "25%"},
				{value: .30, text: "30%"},
				{value: .35, text: "35%"},
				{value: .40, text: "40%"},
				{value: .45, text: "45%"},
				{value: .50, text: "50%"},
			]
		}
	},
	computed: {
		order() {
			return this.$store.state.page?.OrderItemController?.item;
		},
		products() {
			return this.$store.state.page?.OrderItemController?.products;
		},
		modules() {
			return this.$store.state.page?.OrderItemController?.modules;
		},
		totalOrderPrice() {
			return this.currentProducts?.reduce((acc, product) => acc + this.productTotalPrice(product), 0) || 0;
		},
		totalOrderPriceWithoutDiscount() {
			return this.currentProducts?.reduce((acc, product) => acc + this.productTotalPrice(product, true), 0) || 0;
		},
		isExportEnabled() {
			return !!this.$store.state.session?.user?.UF_GOOGLE_TOKEN;
		}
	},
	methods: {
		productPrice(item, skipDiscount = false) {
			if (item.MODULE_IDS?.length) {
				return totalPrice(item.MODULE_IDS?.map(id => this.modules.find(module => id === module.ID)), skipDiscount ? 0 : this.order.DISCOUNT);
			}
			return 0;
		},
		productTotalPrice(item, skipDiscount = false) {
			return this.productPrice(item, skipDiscount) * (item.AMOUNT || 1);
		},
		moduleArticle(modules) {
			return modules.map(id => this.modules?.find(module => module.ID === id).ARTICLE).join(', ')
		},
		modulesOf(product) {
			return this.currentModules = product.MODULE_IDS.map(product => this.modules.find(module => product === module.ID)).filter(el => !!el).map(el => ({...el}));
		},
		productHeight(modules) {
			return modules.map(id => this.modules.find(module => module.ID === id))
				.reduce((acc, {HEIGHT}) => acc + +HEIGHT, 0);
		},
		validate(item, section) {
			/* item.AMOUNT = parseInt(item.AMOUNT);
			 if (isNaN(item.AMOUNT)) item.AMOUNT = 0;
			 else if (item.AMOUNT < 0) item.AMOUNT = 0;
			 //else if (typeof item.actual === "number" && item.QUANTITY > item.actual) item.QUANTITY = item.actual;

			 if (typeof item.rest === "number") {
			   ///item.QUANTITY = item.rest;
			   if (item.AMOUNT > item.rest) item.AMOUNT = item.rest;
			   Vue.set(item, "actual", item.rest - item.AMOUNT);
			 }

			 this.$emit("change", item, section);*/
		},
		validateDebounced: debounce(function (item, section) {
			this.validate(item, section);
		}, Config.DEBOUNCE_INPUT_TIMEOUT),

		onDeleteProduct(item) {
			this.$store.state.dialogDelete.visible = true;
			this.$store.state.dialogDelete.callback = () => {

				const index = this.currentProducts.findIndex(el => el.ID === item.ID);
				this.currentProducts.splice(index, 1);
				this.order.PRODUCT_IDS.splice(index, 1);
				this.order.PRODUCT_COUNTS.splice(index, 1);
				this.$store.state.dialogDelete.visible = false;
			}
		},
		onDeleteOrder() {
			this.$store.state.dialogDelete.visible = true;
			this.$store.state.dialogDelete.callback = () => {
				this.$store.state.dialogDelete.loading = true;

				this.$store.dispatch('del', {
					action: 'OrderItemController',
					params: {
						id: this.order.ID
					}
				})
					.then(() => {
						this.$router.push({name: 'orders'});
					})
					.catch((error) => {
						console.log("ERROR: ", error);
					}).finally(() => {
					this.$store.state.dialogDelete.loading = false;
					this.$store.state.dialogDelete.visible = false;
				})
			}
		},
		onEditProduct(item) {
			this.$router.push({name: 'product', params: {orderId: this.order.ID, id: item.ID}})
		},
		onExport() {
			this.withExportDialog = true;
			this.loadingExport = true;
			this.exportError = null;

			this.$store.dispatch('post', {
				action: 'GoogleSheetController',
				params: {
					id: this.id
				}
			}).then((res) => {
				this.exportUrl = res.page.GoogleSheetController.url;
			}).catch((error) => {
				console.warn("ERROR: ", error);
				if (error.code === Config.ERROR_CODE_EXPIRED) {
					this.exportError = error.error;
					/*this.$store.state.dialogError.visible = true;
					this.$store.state.dialogError.message = error.error;
					this.$store.state.dialogError.callback = () => {
					}*/
				}
			}).finally(() => {
				this.loadingExport = false;
			});
		},
		onEmitInput(item) {
			// todo ужасный прием, вызывать геттер без получателя, - исправить на сеттер или переименовать метод
			this.productTotalPrice(item);
		},
		onFocus(event) {
			event.target.select();
		},
		onInput(item, section) {
			this.validateDebounced(item, section);
		},
		onChange(item, section) {
			this.validate(item, section);
		},
		onSave() {
			this.loadingSave = true;
			this.order.PRODUCT_COUNTS = JSON.stringify(this.order.PRODUCT_IDS.map((_, index) => this.currentProducts[index]?.AMOUNT.toString()));
			//this.order.SUM = this.totalOrderPrice;

			this.$store.dispatch('post', {
				action: 'OrderItemController',
				params: {
					item: this.order
				}
			})
				.then(() => {
					//this.$router.push({name: 'orders'});
					this.isSaved = true;
					setTimeout(_ => this.isSaved = false, 2000);
				})
				.catch((error) => {
					console.log("ERROR: ", error);
				}).finally(() => {
				this.loadingSave = false;
			});
		},
	},
	mounted() {
		this.currentProducts = this.order?.PRODUCT_IDS?.map(productId => this.products.find(product => product.ID === productId));

		this.currentProducts?.forEach((product, index) => {
			if (this.order.PRODUCT_COUNTS && this.order.PRODUCT_COUNTS[index]) {
				this.$set(product, 'AMOUNT', Number(this.order.PRODUCT_COUNTS[index]));
			} else {
				this.$set(product, 'AMOUNT', 1);
			}
		});
	}
}
</script>

<style lang="scss">
.order {
	tr {
		cursor: pointer !important;
	}

	&__expand-row {
		padding: 0 !important;
		cursor: unset;
	}

	.v-data-table__expanded {
		box-shadow: none !important;
		cursor: unset;
	}

}

/*.dialog {
	&__order {
		padding: 24px 32px;
	}

	&__delete {
		text-align: center;
		padding: 20px 32px;
	}
}*/
</style>
